@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap');

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
}
@media (max-width: 767px) {
    body,
    .card,
    .bgImg {
      max-width: 100%;
      width: 100%;
    }
  }
  
  
.card img {
    height: 238px;
}

.bgImg {
    background: linear-gradient(45deg, #333, hsla(0, 0%, 100%, 0)), url(https://t4.ftcdn.net/jpg/02/80/09/93/360_F_280099377_S0Rg7QpSrWkRigfrivnNnZ5uxRtTlCZ3.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
}

@media (max-width: 767px) {
    .card img {
        height: auto;
    }
}
