.course-card {
  animation: zoom-out-up 1s ease-out both, breathe 1.3s ease-in-out infinite alternate;
  border: 2px solid #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  overflow: hidden;
}

@keyframes zoom-out-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.button-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: translateY(-8px);
}

.neon-border {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  border: 2px solid #fff;
  border-radius: 20px;
  animation: neon-rotate 1s ease-in-out infinite alternate;
}

@keyframes neon-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
