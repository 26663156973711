@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
}
@media (max-width: 767px) {
    body,
    .card,
    .bgImg {
      max-width: 100%;
      width: 100%;
    }
  }
  
  
.card img {
    height: 238px;
}

.bgImg {
    background: linear-gradient(45deg, #333, hsla(0, 0%, 100%, 0)), url(https://t4.ftcdn.net/jpg/02/80/09/93/360_F_280099377_S0Rg7QpSrWkRigfrivnNnZ5uxRtTlCZ3.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-attachment: fixed;
}

@media (max-width: 767px) {
    .card img {
        height: auto;
    }
}

.course-card {
  -webkit-animation: zoom-out-up 1s ease-out both, breathe 1.3s ease-in-out infinite alternate;
          animation: zoom-out-up 1s ease-out both, breathe 1.3s ease-in-out infinite alternate;
  border: 2px solid #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  overflow: hidden;
}

@-webkit-keyframes zoom-out-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes zoom-out-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes breathe {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes breathe {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.button-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.neon-border {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  border: 2px solid #fff;
  border-radius: 20px;
  -webkit-animation: neon-rotate 1s ease-in-out infinite alternate;
          animation: neon-rotate 1s ease-in-out infinite alternate;
}

@-webkit-keyframes neon-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes neon-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.color-bg {
    background-color: floralwhite;
}
.header-row {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 991px) {
    .header-row {
      height: 60vh;
    }
  }
  
  @media (max-width: 767px) {
    .header-row {
      height: 40vh;
    }
  }
  
